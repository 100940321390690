import React from 'react'
import "../styles/LandingBox.css"
import BannerButton from './BannerButton'
import gsap from "gsap"
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import {useInfo} from "../context/PageContext"
gsap.registerPlugin(ScrollTrigger)

function LandingBox() {
  const [info] = useInfo()

useEffect(()=>{
    gsap.fromTo(".landingBox-Body", {opacity: 0, x: 150}, {opacity: 1, x:0, duration:1.5})
    gsap.fromTo(".landingBox-Header", {opacity: 0, x: -150}, {opacity: 1, x:0, duration:1.5})
    gsap.to(".content-Container",
  {
    y: -60,
    scrollTrigger: {
      trigger: ".landingBox-Header",
      scrub: 1,
      start: "top 30%",
    },
  }
  )
  gsap.to(".landingBox-Img",
  {
    y: 100,
    scrollTrigger: {
      trigger: ".partner-Container",
      scrub: true,
      start: "bottom bottom",
      end: "top top",
    },
  }
  )
},[])


  return (
    <>
    <div className='landingBox-Container'>
      <div className='landingBox'>
          <div className='landingBox-Body-Container'>
            <div className='content-Container'>
              <h1 className='landingBox-Header'>VI HJÄLPER DIG HITTA RÄTT PORT.</h1>
              <h2 className='landingBox-Body'>Främsta återförsäjaren av EAB portar i Stockholm sedan 1997</h2>
              <BannerButton id="Bbb" btnTo = "/contact" body="KONTAKTA OSS!"></BannerButton>
            </div>
          </div>
          <img className="landingBox-Img" alt='En backgrundsbild på en port' src={"https://firebasestorage.googleapis.com/v0/b/bbk-wp-db.appspot.com/o/LandingPage%2Fport_1280x853.jpg?alt=media&token=79ea9702-cf87-4473-9441-486e66783118"}></img>
      </div>
      <div className='partner-Container'>
        {info?.misc[0]?.PrevCustomers.map((logo) => (
          <img className='partner-Logo' alt="Logotyp av företag som tidigare varit/är kund" src={logo}></img>
        ))}
        </div>
    </div>
    </>
  )
}

export default LandingBox