import {initializeApp} from "firebase/app"
import { getStorage } from "firebase/storage"
import {getFirestore} from "firebase/firestore";
import {getFunctions} from "firebase/functions"

const firebaseConfig = {
    apiKey: "AIzaSyDEY7Jcas4SOfq2uSGGZY7iqmtkcQnzjzA",
    authDomain: "bbk-wp-db.firebaseapp.com",
    projectId: "bbk-wp-db",
    storageBucket: "bbk-wp-db.appspot.com",
    messagingSenderId: "487104912129",
    appId: "1:487104912129:web:9f2e181e958bfb466cd900",
    measurementId: "G-HWTSB67RXC"
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const storage = getStorage(app);
const functions = getFunctions(app);

export {
    db, storage, functions
}