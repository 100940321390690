import React, { useEffect,useRef,useState } from 'react'
import { ref, listAll, getDownloadURL } from "firebase/storage";
import {storage} from "../context/firebase"
import { useParams } from 'react-router-dom';
import "../styles/ProductGallery.css"
import ImgViewer from './ImgViewer';
import { useImgViewer } from '../context/ImgViewerContex';
import gsap from 'gsap';

function ProductGallery() {
  const tempHolder = []
  const [urlHolder, setUrlHolder] = useState([])
  const [imgViewr, setImgViewr] = useImgViewer()
  const id = useParams()

  async function getImgsList(){
   const imgRef = ref(storage, "/" + id.typ + "/")
   const imgList = await listAll(imgRef)
   imgList.items.forEach((res) => {
    getDownloadURL(res).then((url) => {
      tempHolder.push(url)
    }).then(() => {
      setUrlHolder([...tempHolder])
    })
   });
  }

  function openImgViewr(src){
    setImgViewr({src: src, isOpen:true})
  }

  useEffect(() => {
    gsap.fromTo(".expandedImg", {scale:0.3}, {scale:1, duration: 0.1})
  },[imgViewr])

  useEffect(() => {
    getImgsList()
  },[])

  return (
    <>
    { urlHolder.length > 1?
      <div className='product-Gallery-Container'>
      <div className='product-Gallery-Row'>
        {urlHolder?.map((url) => 
        ( <img onClick={(e) => openImgViewr(url)} src={url} className='product-Gallery-Img'></img>))}
      </div>
    </div>:[<div className='product-Gallery-Container'></div>]
    }
    {imgViewr.isOpen == true?
     <ImgViewer img={imgViewr.src}></ImgViewer>:[]
    }
    </>
  )
}

export default ProductGallery