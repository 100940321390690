import React from 'react'
import "../styles/ProductInfo.css"
import {useInfo} from "../context/PageContext"

function ProductInfo(props) {

    const index = props.index
    const [info] = useInfo()
    const pInfo = info.data[index]

    const prodRowB = ["Vi vet att våra portar håller - och att de håller vad vi lovar. Därför ingår alltid 10 års garanti oavsett vilken lösning du väljer."]

  return (
    <div className='product-Info-Container'>
        {
            pInfo.Row?
            <div className='product-Info-Row'>
            <div className='product-Row-Box'>
                <header className='product-Rbox-Titel'>{pInfo.Row[0].Header.toUpperCase()}</header>
                <p className='product-Rbox-Body'>{pInfo.Row[0].Body}</p>
            </div>
            <div className='product-Row-Box'>
                <header className='product-Rbox-Titel'>{pInfo.Row[1].Header.toUpperCase()}</header>
                <p className='product-Rbox-Body'>{pInfo.Row[1].Body}</p>
            </div>
            <div className='product-Row-Box'>
                <header className='product-Rbox-Titel'>ETT LÖFTE MED 10 ÅRS GARANTI</header>
                <p className='product-Rbox-Body'>{prodRowB[0]}</p>
            </div>
        </div>:[]
        }
        {
            pInfo.MidBox? 
            <div className='product-Info-Body'>
            <div className='product-Body-Box'>
                <div className='product-Bbox-Info'>
                    <div className='product-Bbox-Conainer'>
                    <header className='product-Bbox-Title'>{pInfo.MidBox.Header.toUpperCase()}</header>
                    <p className='product-Bbox-Body'>{pInfo.MidBox.Body}</p>
                    </div>
                    <img src={pInfo.MidBox.Img}className='product-Bbox-Img'></img>
                </div>
            </div>
        </div>:[]

        }
        {
            pInfo.InfoCards? 
            <div className='product-Bbox-Info'>
                 {pInfo.InfoCards.map((card) => (
                            <div className='product-Bbox-Card'>
                                {card.Img?
                                    <img src={card.Img}className='product-Card-Img'></img>:[]
                                }
                                <header className='product-Bbox-Title'>{card.Header.toUpperCase()}</header>
                                <p className='product-Bbox-Body'>{card.Body}</p>
                            </div>
                    ))}
            </div>:[]
        }

        {
            pInfo.SpecField?

            <div className='product-Info-Body'>
                <div className='product-Body-Box'>
                    <div className='product-Bbox-Info'>{
                    pInfo.SpecField[0].Img?
                    <img src={pInfo.SpecField[0].Img}className='product-Bbox-Img'></img>:[]
                    }
                            <div className='product-Bbox-Conainer'>
                                <div className='product-Bbox-List'>
                                    <header className='product-Bbox-Title'>{pInfo.SpecField[0].Header.toUpperCase()}</header>
                                    {pInfo.SpecField[0].Items.map((item) => (<p className='product-Bbox-Item'>{item}</p>))}
                                </div>
                            </div>
                        </div>
                        {pInfo.SpecField[1]?
                            <div className='product-Bbox-Info'>
                            <div className='product-Bbox-Conainer'>
                                <div className='product-Bbox-List'>
                                {   pInfo.SpecField.slice(1, pInfo.SpecField.length).map((card) => (
                                    <>
                                        <header className='product-Bbox-Title'>{card.Header?.toUpperCase()}</header>
                                        {card.Items.map((item) => (<p className='product-Bbox-Item'>{item}</p>))}    
                                    </>
                                ))}
                            </div>
                        </div>{pInfo.SpecField[1]?.Img?
                            <img src={pInfo.SpecField[1]?.Img}className='product-Bbox-Img'></img>:[]
                            }
                    </div>:[]


                        }
                </div>
                
            </div>:[]

        }
    </div>
  )
}

export default ProductInfo