import React from 'react'
import "../styles/SideBar.css"
import { useSideBar } from '../context/SideBarContext'
import {useState} from "react"
import NavButton from "../components/NavButton"
import DokumentList from './DokumentList'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons"
import SparesList from './SparesList'
import gsap from 'gsap'

export default function SideBar() {
  const [sideBar, setSideBar] = useSideBar()
  const [focusDoc, setFocusDoc] = useState(false)
  const [focusSpare, setFocusSpare] = useState(false)
  const [docChev, setDocChev] = useState(faChevronDown)
  const [spareChev, setSpareChev] = useState(faChevronDown)
  
  function exitOnClick(){
   const sBar = document.getElementById("sideBar")
   gsap.fromTo(".sideBar", {x:0}, {x:500, duration:0.2})
   setTimeout(() => {
    sBar.style.visibility = "hidden"
    setDocChev(faChevronDown)
    setSpareChev(faChevronDown)
   }, 200);
  }

  function focusOnDoc(){
    const documentList = document.getElementById("dLc")
    if (focusDoc === false){
      documentList.style.display = "block"
      setFocusDoc(true)
      setDocChev(faChevronUp)
    }
    if (focusDoc === true){
      documentList.style.display = "none"
      setFocusDoc(false)
      setDocChev(faChevronDown)
    }
  }

  function focusOnSpare(){
    const spareList = document.getElementById("sLc")
    if (focusSpare === false){
      spareList.style.display = "block"
      setFocusSpare(true)
      setSpareChev(faChevronUp)
    }
    if (focusSpare === true){
      spareList.style.display = "none"
      setFocusSpare(false)
      setSpareChev(faChevronDown)
    }
  }

  return (
    <div id="sideBar" className='sideBar-Container'>
      <div className='sideBar'>
        <div className='exitBtn-Container'>
          <button className='exitBtn' onClick={(e) => exitOnClick()}>X</button>
        </div>
        {sideBar.type === "nav"?
        <div className='sideBarContent'>
          <ul className="sideBar-button-List">
            <div className='sideBar-Button'>
              <NavButton color = "W" type="HEM"></NavButton>
            </div>
            <div className='sideBar-Button'>
              <NavButton color = "W" type="EAB PORTAR"></NavButton>
            </div>
            <div className='sideBar-Button'>
              <NavButton color = "W" type="OM OSS"></NavButton>
            </div>
            <div className='sideBar-Button'>
              <NavButton color = "W" sb = {true} type="KONTAKT"></NavButton>
            </div>
        </ul>
        </div>:
        sideBar.type === "spec"?
        <div className='sideBarContent'>
          <div className='siderBar-Collection'>
            <div className='collection-Title-Container'>
              <p onClick={(e) => focusOnDoc()} className='collection-Title'>DOKUMENT</p>
              <FontAwesomeIcon className="dd-Icon" icon={docChev}></FontAwesomeIcon>
            </div>
              <DokumentList></DokumentList>
          </div>
          <div className='siderBar-Collection'>
            <div className='collection-Title-Container'>
              <p onClick={(e) => focusOnSpare()} className='collection-Title'>TILLBEHÖR</p>
              <FontAwesomeIcon className="dd-Icon" icon={spareChev}></FontAwesomeIcon>
            </div>
              <SparesList></SparesList> 
          </div>
        </div>:
        []
        }
      </div>
    </div>
  )
}
