import React from "react";
import "../styles/NavButton.css";
import {Link} from "react-router-dom"

function NavButton(props) {
  let link 

  if(props.type === "HEM"){
    link = "/"
  }else if(props.type === "OM OSS"){
    link = "/about"
  }else if(props.type === "KONTAKT"){
    link = "/contact"
  }else if(props.type === "EAB PORTAR"){
    link = "/portar"
  }

  return (<Link to={link} id={`nVC-${props.type}`} className="navButton-Container">
        {
          props.sb ?
          <button id={`sb-${props.type}`} className={`nav-${props.color}-Button`}>{props.type}</button>:
          <button id={props.type} className={`nav-${props.color}-Button`}>{props.type}</button>
        }
      </Link>)
}

export default NavButton;
