import React from 'react'
import NavBar from '../components/WhiteNavBar'
import FooterBar from '../components/FooterBar'
import "../styles/ErrorPage.css"
import {Link} from "react-router-dom"

function ErrorPage() {
  return (
    <>
    <NavBar></NavBar>
    <div className='error-Msg-Container'>
        <div className='error-Msg-Box'>
            <header className='error-Msg-Header'>ERROR 404</header>
            <p className='error-Msg-Body'>KUNDE INTE HITTA SIDAN DU SÖKER</p>
            <p className='error-Msg-Body'>Gå tillbaka till start sidan</p>
            <Link to={"/"}> 
            <button className='HomeBtn'>HEM</button>
            </Link>
        </div>
    </div>
    <FooterBar></FooterBar>
    </>
  )
}

export default ErrorPage