import React,{useEffect} from 'react'
import NavBar from '../components/WhiteNavBar'
import FooterBar from '../components/FooterBar'
import ProductGrid from '../components/ProductGrid'
import { TabTitle } from '../context/GeneralFunctions'

function ProductPage() {
  TabTitle("Bebeco - Portar")
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <>
    <NavBar></NavBar>
    <div className='Body'>
    <ProductGrid></ProductGrid>
    </div>
    <FooterBar></FooterBar>
    </>
  )
}

export default ProductPage