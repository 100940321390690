import React from 'react'
import "../styles/ImgViewer.css"
import { useImgViewer } from '../context/ImgViewerContex'
import gsap from 'gsap'

function ImgViewer(props) {
    const [imgViewer, setImgViewer] = useImgViewer()

  return (
    <div onClick={(e) => setImgViewer({isFalse: false})} className='imgView-Container'>
        <div className='imgView'>
            <img src={props.img} className='expandedImg'></img>
        </div>
    </div>
  )
}

export default ImgViewer

