import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useInfo } from '../context/PageContext'
import "../styles/DocumentList.css"

function DokumentList(props) {
   const [docList, setDocList] = useState()
   const [info] = useInfo()

   useEffect(()=>{
    info.data.map((x, i) => {
      if(x.Hero.Header == id.typ){
        setDocList(info.data[i].Dokument)
        return
      }
    })
  },[info])

   const id = useParams()
  return (
    <div id = "dLc" className='documentList-Container'>
      {
        docList?
        <ul className='documentList'>
        {docList.map((item) => (<a className='pdfDocument' href={item.Url} target="_blank" >{item.Title}</a>))}
        </ul>:[]
      }
    </div>
  )
}

export default DokumentList