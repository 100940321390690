import React from 'react'
import "../styles/ContactMailForm.css"
import PortForm from './PortForm'
import {functions} from "../context/firebase"
import { httpsCallable } from 'firebase/functions'
import {useFormik} from "formik"


function ContactMailForm() {

  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      street: "",
      adress: "",
      email: "",
      phoneNr: "",
      msg: "",
      portar: [{
        typ: "Vet ej",
        antal: 1,
        höjd: 3000,
        bredd: 5000,
        glas: "Nej",
        glasRader: "0",
        beklädnadsplåt:"Nej",
        färg:"-",
        nyPort:"Nej",
        index:"1",
        montage:"Nej"
      }]
    },
    onSubmit: (values) => {
     const priceRequestMail = httpsCallable(functions, "priceRequestMail");
      priceRequestMail(values).then(result =>{
        window.alert("Prisförfrågan skickad!")
      }).catch((err) => {
        window.alert("Kunde inte skicka prisförfrågan. Kontakta oss gärna via mail eller telefon")
        console.log(err)
      }) 
    }
   })

  return (
    <div className='ContactMailForm-Container'>
        <form onSubmit={formik.handleSubmit} className='ContactMailForm'>
          <h2 className='ContactMailForm-Header'>Prisförfrågan</h2>
            <div className='ContactMailFormCredentialsForm'>

            <label className='ContactMailFormLabel'>Namn<span className="isRequired">*</span></label>
            <input name = "name" onChange={formik?.handleChange} className='ContactMailFormInput-Text' placeholder='För och Efternamn' required={true} value = {formik?.values.name} type={"text"}></input>

            <label className='ContactMailFormLabel'>Företag</label>
            <input name = "company" onChange={formik?.handleChange} value = {formik?.values.company} className='ContactMailFormInput-Text' type={"text"}></input>

            <label className='ContactMailFormLabel'>Adress<span className="isRequired">*</span></label>
            <input name = "street" value = {formik?.values.street} onChange={formik?.handleChange} className='ContactMailFormInput-Text' placeholder='Gata' type={"text"}></input>
            <input name = "adress"  onChange={formik?.handleChange} value = {formik?.values.adress} className='ContactMailFormInput-Text' placeholder='Postnummer och ort' required={true} type={"text"}></input>

            <label className='ContactMailFormLabel'>E-mail<span className="isRequired">*</span></label>
            <input name = "email"  onChange={formik?.handleChange}  value = {formik?.values.email} className='ContactMailFormInput-Text' required={true} placeholder='Email'type={"email"}></input>

            <label  className='ContactMailFormLabel'>Telefonnummer<span className="isRequired">*</span></label>
            <input name = "phoneNr" value = {formik?.values.phoneNr}  onChange={formik?.handleChange} className='ContactMailFormInput-Text' required={true} placeholder='Telefonummer' type={"tel"}></input>

            <label className='ContactMailFormLabel'>Meddelande</label>
            <input name = "msg" value = {formik?.values.msg}  onChange={formik?.handleChange} className='ContactMailFormInput-Text' type={"text"}></input>


            <PortForm formik = {formik}></PortForm>

            <button type="submit" className='ContactMialFormButton'>Skicka</button>

            </div>
        </form>
    </div>
  )
}

export default ContactMailForm