import React from 'react'
import "../styles/ProductGrid.css"
import ProductCard from './ProductCard'
import { useEffect, useState } from 'react'
import gsap from 'gsap'
import {useInfo} from "../context/PageContext"

function ProductGrid() {
  const [data] = useInfo()

  return (
    <div className='product-Grid-Container'>
        <h1 className='product-Grid-Title'>EAB PORTAR</h1>
        <div className='product-Grid'>
          {data.data?.map((info, i)=>(
             <ProductCard img = {info.Hero.Img} title={info.Hero.Header} body={info.Hero.Body}/>
          ))}
        </div>
    </div>
  )
}

export default ProductGrid