import React from 'react'
import "../styles/ProductNav.css"
import SideBar from '../components/SideBar'
import {useRef} from "react"
import { useSideBar } from "../context/SideBarContext";
import gsap from 'gsap';

function ProductNav() {
  const specRef = useRef()
  const [sideBar, setSideBar] = useSideBar()
  function openMenu(ele){
    const sMenu = document.getElementById("sideBar")
    sMenu.style.visibility = "visible"
    gsap.fromTo(".sideBar", {x:500}, {x:0, duration:0.2})
    if (ele === 1){
      setSideBar({type: "spec", open: true, focus: "doc"})
    }
    if (ele === 2){
      setSideBar({type: "spec", open: true, focus: "spare"})
    }
  }

  return (
    <>
     <div className='product-Nav-Container'>
        <div className='product-Nav-List'>
            <button ref={specRef} onClick={(e) => openMenu(1)} value="DOKUMENT" className='product-List-Btn'>DOKUMENT & TILLBEHÖR</button>
        </div>
    </div>
      <SideBar> </SideBar>
    </>
  )
}

export default ProductNav