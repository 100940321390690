import React,{useEffect} from 'react'
import "../styles/ContactMailForm.css"

function SpecPortForm(props) {

    const {formik} = props
    const {index} = props

    useEffect(() => {
        if(props.typ === "Vikport"){
            formik.values.portar[index] = {...formik.values.portar[index], 
                drift: "Handdriven", 
                utförande: "Inåtgående",
                isolering: "Oisolerad",
                säkerhet: "Nej",
                karm: "Nej",
                djup: "-",
                blad: "-", 
            }
        }
        if(props.typ === "Slagport"){
            formik.values.portar[index] = {...formik.values.portar[index], 
                blad: "Enkel", 
                isolering: "Oisolerad",
                utförande: "-",
                säkerhet: "-",
                drift: "-", 
                karm: "Nej",
                djup: "-"
        }}
        if(props.typ === "Gångdörr"){
            formik.values.portar[index] = {...formik.values.portar[index], 
            karm: "Nej",
            isolering: "Oisolerad",
            utförande: "-",
            drift: "-",
            blad: "-", 
            säkerhet: "-", 
            }
        }
    },[ formik.values.portar[index]?.typ])

  return (
    <div className='ContactMailFormSpec'>
        <h2 className='SpecFormHeader'>SPECIFIKATION FÖR {props.typ.toUpperCase()}</h2>
        {
            props.typ === "Vikport"?
            <>
                <label className='ContactMailFormLabel'>Drift</label>
                <select name={`portar.${index}.drift`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Handdriven"}>Handdriven</option>
                    <option value={"Eldriven"}>Eldriven</option>
                </select>

                <label className='ContactMailFormLabel'>Utförande</label>
                <select name={`portar.${index}.utförande`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Inåtgående"}>Inåtgående</option>
                    <option value={"Utgående"}>Utgående</option>
                </select>

                <label className='ContactMailFormLabel'>Isolering</label>
                <select name={`portar.${index}.isolering`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Oisolerad"}>Oisolerad</option>
                    <option value={"Standardisolerad"}>Standardisolerad</option>
                    <option value={"Högisolerad"}>Högisolerad</option>
                </select>

                <label className='ContactMailFormLabel'>Karm</label>
                <select name={`portar.${index}.karm`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Nej"}>Nej</option>
                    <option value={"L-stål"}>L-stål</option>
                    <option value={"U-stål"}>U-stål</option>
                    <option value={"Vet ej"}>Vet ej</option>
                </select>
                {
                formik.values.portar[index]?.karm !== "Nej"?
                    <>
                        <label className='ContactMailFormLabel'>Väggtjocklek {"(mm)"}</label>
                        <input name={`portar.${index}.djup`} onChange={formik.handleChange} className='ContactMailFormInput-Number' type={"number"}></input>
                    </>:[]
                }
                

                <label className='ContactMailFormLabel'>Säkerhetsklassad</label>
                <select name={`portar.${index}.säkerhet`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Nej"}>Nej</option>
                    <option value={"Ja"}>Ja</option>
                </select>
            
            </>:
            props.typ === "Slagport"?
            <>
                <label className='ContactMailFormLabel'>Portblad</label>
                <select name={`portar.${index}.blad`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Enkel"}>Enkel</option>
                    <option value={"Dubbel"}>Dubbel</option>
                </select>

                <label className='ContactMailFormLabel'>Karm</label>
                <select name={`portar.${index}.karm`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Nej"}>Nej</option>
                    <option value={"L-stål"}>L-stål</option>
                    <option value={"U-stål"}>U-stål</option>
                    <option value={"Fyrkantsrör"}>Fyrkantsrör</option>
                    <option value={"Vet ej"}>Vet ej</option>
                </select>

                {
                formik.values.portar[index]?.karm != "Nej"?
                    <>
                        <label className='ContactMailFormLabel'>Väggtjocklek {"(mm)"}</label>
                        <input  name={`portar.${index}.djup`} onChange={formik.handleChange} className='ContactMailFormInput-Number' type={"number"}></input>
                    </>:[]
                }

                <label className='ContactMailFormLabel'>Isolering</label>
                <select name={`portar.${index}.isolering`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Oisolerad"}>Oisolerad</option>
                    <option value={"Standardisolerad"}>Standardisolerad</option>
                    <option value={"Högisolerad"}>Högisolerad</option>
                </select>

            </>:
            props.typ === "Gångdörr"?
            <>

                <label className='ContactMailFormLabel'>Karm</label>
                <select  name={`portar.${index}.karm`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Nej"}>Nej</option>
                    <option value={"Ja"}>L-stål</option>
                </select>

                <label className='ContactMailFormLabel'>Isolering</label>
                <select name={`portar.${index}.isolering`} onChange={formik.handleChange} className='ContactMailFormSelect'>
                    <option value={"Oisolerad"}>Oisolerad</option>
                    <option value={"Standardisolerad"}>Standardisolerad</option>
                    <option value={"Högisolerad"}>Högisolerad</option>
                </select>
            
            </>:[]
        }
    </div>
  )
}

export default SpecPortForm