import React, { useContext} from "react"
import { useState } from "react"

const FormContext = React.createContext()

export function useForm(){
    return useContext(FormContext)
} 

export function FormProvider({children}){
    const [portList, setPortList] = useState({})
    const [formCount, setFormCount] = useState(0)

 return (
    <FormContext.Provider value = {[portList, setPortList, formCount, setFormCount]}>
        {children}
    </FormContext.Provider>
 )
}

export default FormContext