import React from "react";
import NavButton from "./NavButton";
import "../styles/WhiteNavBar.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBars} from "@fortawesome/free-solid-svg-icons"
import SideBar from "./SideBar";
import { useSideBar } from "../context/SideBarContext";
import gsap from "gsap";
import { Link } from "react-router-dom";
import { useInfo } from "../context/PageContext";  

function WhiteNavBar() {
  const [info] = useInfo()
  const [sideBar, setSideBar] = useSideBar()
  function openMenu(){
    const sMenu = document.getElementById("sideBar")
    sMenu.style.visibility = "visible"
    gsap.fromTo(".sideBar", {x:500}, {x:0, duration:0.2})
    setSideBar({type: "nav", open: true})
  }

  return (
    <div className="navBar-W-Container">
      <Link to={"/"}>
        <img
        className="navBar-Logo"
        src={info?.misc[0]?.Logo}
        alt="Company(BBK) blue and rend logo"
      ></img>
      </Link>
    <div className="button-List-Container">
      <ul className="button-W-List">
        <NavButton color = "W" type="HEM"></NavButton>
        <NavButton color = "W" type="EAB PORTAR"></NavButton>
        <NavButton color = "W" type="OM OSS"></NavButton>
        <NavButton color = "W" type="KONTAKT"></NavButton>
      </ul>
    </div>
    <div className="dd-Menu-Container">
        <FontAwesomeIcon onClick={(e) => openMenu()} icon={faBars} />
    </div>
    <SideBar></SideBar>
  </div>
  )
}

export default WhiteNavBar