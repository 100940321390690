import React from 'react'
import "../styles/ProductHero.css"


function ProductHero(props) {
  return (
    <div className='product-Hero-Container'>
        <div className='product-Hero'>
            <img src={props.img} className='product-Hero-bg'></img>
        </div>
        <div className='product-Hero-Blur'>
            <header className='product-Hero-Title'>{props.title}</header>
             <p className='product-Hero-Body'>{props.body}</p>
        </div>
    </div>
  )
}

export default ProductHero