import React from 'react'
import { useContext, useState } from 'react'

const ImgViewerContex = React.createContext()

export function useImgViewer(){
    return useContext(ImgViewerContex)
}

export function ImgViewerProvider({children}) {
    const [ImgViewer, setImgViewer] = useState({src:"", isOpen: false}) 

  return (
    <ImgViewerContex.Provider value={[ImgViewer, setImgViewer]}>
        {children}
    </ImgViewerContex.Provider>
  )
}

export default ImgViewerContex