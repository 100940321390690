import "./styles/App.css";
import "./components/NavBar";
import LandingPage from "./pages/LandingPage";
import ProductPage from "./pages/ProductPage"
import AboutPage from "./pages/AboutPage";
import Contact from "./pages/Contact";
import ArticlePage from "./pages/ArticlePage";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import {PageProvider} from "./context/PageContext"
import {SideBarProvider} from "./context/SideBarContext"
import ErrorPage from "./pages/ErrorPage";
import { ImgViewerProvider } from "./context/ImgViewerContex";
import { FormProvider } from "./context/FormContext";


function App() {
  return (
    <PageProvider>
      <SideBarProvider>
        <ImgViewerProvider>
          <FormProvider>
            <div className="App">
              <Router>
                <Routes>
                  <Route exact path="*" element={<ErrorPage/>}></Route>
                  <Route exact path="/" element={<LandingPage/>}></Route>
                  <Route exact path="/portar" element={<ProductPage/>}></Route>
                  <Route exact path="/about" element={<AboutPage/>}></Route>
                  <Route exact path="/contact" element={<Contact/>}></Route>
                  <Route exact path="/portar/:typ" element={<ArticlePage/>}></Route>
                </Routes>
              </Router>
            </div>
          </FormProvider>
        </ImgViewerProvider>
      </SideBarProvider>
    </PageProvider>
  );
}

export default App;
