import "../styles/ContactMailForm.css"

import React,{useEffect} from 'react'
import SpecPortForm from "./SpecPortForm"
import { Formik, Field, ErrorMessage, FieldArray, } from 'formik';

function PortForm(props) {

    const {formik} = props

    const standardColors = 
    [
    "Silvermetallic (RAL 9006)",
    "Svart (RAL 9005/NCS S 9000-N)",
    "Kritvit (RAL 9010/NCS S 1002-G)",
    "Mörkgrå (RAL 7043/NCS S7005-B20G)",
    "Mörkröd (RAL 3009/NCS S5030-R90B)",
    "Barrgrön (RAL 6020)",
    "Azurblå (RAL 5009/NCS S5030-R90B"
    ]

    
    const initialValues = {
        portar: [
            {
                typ: "",
                antal: 1,
                höjd: 3000,
                bredd: 5000,
                glas: "Nej",
                glasRader: "0",
                beklädnadsplåt:"Nej",
                färg:"",
                index:"1",
                montage:"Nej",
            }
        ]
    }

    function addForm(e,v,i){
        if(e.target.value === "Ja"){
            v.portar.push({typ:"Vet Ej", antal: 1, höjd: 2000, bredd: 5000, glas: "Nej", glasRader:0, beklädnadsplåt:"Nej", färg: "Vet Ej", index:i+2})
            formik.values.portar.push({typ:"", antal: 1, höjd: 2000, bredd: 5000, glas: "Nej", glasRader:0, beklädnadsplåt:"Nej", färg: "", index:i+2})
            e.target.blur()
        }
        if(e.target.value === "Nej"){
            v.portar.splice(i+1,1)
            formik.values.portar.splice(i+1,1)
            e.target.blur()
        }
    }


  return (
    <div className="portSpecForm">
        <Formik initialValues = {initialValues}>
            {({values}) => (
                 <FieldArray name="portar">
                 {({insert, remove, push}) => (
                     <>
                        {values?.portar?.map((port, index) => (
                            <div className="portSpecForm" key={index}>
                                <h3 className="ContactMailForm-PortNr">PORT SPECIFIKATION {index + 1}</h3>

                                <label className='ContactMailFormLabel'>Typ av port<span className="isRequired">*</span></label>
                                <Field name={`portar.${index}.typ`} as="select" value={formik.values.portar[index]?.typ} onChange={formik.handleChange} className='ContactMailFormSelect' required={true}>
                                    <option value={"Vet ej"}>Vet ej</option>
                                    <option value={"Vikport"}>Vikport</option>
                                    <option value={"Skjutport"}>Skjutport</option>
                                    <option value={"Slagport"}>Slagport</option>
                                    <option value={"Gångdörr"}>Gångdörr</option>
                                </Field>
        
                                <label className='ContactMailFormLabel'>Bredd {"(mm)"}<span className="isRequired">*</span></label>
                                <Field name= {`portar.${index}.bredd`} value={formik.values.portar[index]?.bredd} onChange={formik.handleChange} className='ContactMailFormInput-Number' required={true}  minLength={4} type={"number"}></Field>
        
                                <label className='ContactMailFormLabel'>Höjd {"(mm)"}<span className="isRequired">*</span></label>
                                <Field  name={`portar.${index}.höjd`} value={formik.values.portar[index]?.höjd} onChange={formik.handleChange} className='ContactMailFormInput-Number' minLength={4} required={true} type={"number"}></Field>
        
                                <label className='ContactMailFormLabel'>Glas<span className="isRequired">*</span></label>
                                <Field name={`portar.${index}.glas`} value={formik.values.portar[index]?.glas} onChange={formik.handleChange} as="select" required={true} className='ContactMailFormSelect'>
                                    <option value={"Nej"}>Nej</option>
                                    <option value={"Ja"}>Ja</option>
                                </Field>
                                {
                                    formik.values.portar[index]?.glas === "Ja"?
                                    <>
                                    <label className='ContactMailFormLabel'>Antal rader glas</label>
                                    <Field name={`portar.${index}.glasRader`} value={formik.values.portar[index]?.glasRader} onChange={formik.handleChange} className='ContactMailFormInput-Number' type={"number"}></Field>
                                    </>:[]
                                }
        
                                <label className='ContactMailFormLabel'>Lackerad beklädnadsplåt</label>
                                <Field name={`portar.${index}.beklädnadsplåt`} as="select" value={formik.values.portar[index]?.beklädnadsplåt} onChange={formik.handleChange} className='ContactMailFormSelect'>
                                    <option value={"Nej"}>Nej</option>
                                    <option value={"Ja"}>Ja</option>
                                </Field>
        
                                {
                                    formik.values.portar[index]?.beklädnadsplåt === "Ja"?
                                    <>
                                         <Field as = "select" name={`portar.${index}.färg`} value={formik.values.portar[index]?.color} onChange={formik.handleChange} className="ContactMailFormSelect">
                                         <option value={"Annan (inte standard kulör)"}>Annan (inte standard kulör)</option>
                                        {standardColors.map((color, i) => (  
                                                <option key = {i} value={color}>{color}</option>
                                        ))}
                                         </Field>
                                    </>:[]
                                }
        
                                {
                                formik.values.portar[index]?.typ === "Vikport"?
                                    <SpecPortForm index = {index} formik = {formik} typ={formik.values.portar[index]?.typ}></SpecPortForm>:
                                    formik.values.portar[index]?.typ === "Slagport"?
                                    <SpecPortForm index = {index} formik = {formik} typ={formik.values.portar[index]?.typ}></SpecPortForm>:
                                    formik.values.portar[index]?.typ === "Gångdörr"?
                                    <SpecPortForm index = {index} formik = {formik} typ={formik.values.portar[index]?.typ}></SpecPortForm>:[]
        
                                }
        
                                <label className='ContactMailFormLabel'>Antal portar {"(av denna specifikation)"}</label>
                                <Field name = {`portar.${index}.antal`} value={formik.values.portar[index]?.antal} onChange={formik.handleChange} className='ContactMailFormInput-Number' type={"number"}></Field>
        
                                <label className="ContactMailFormLabel">Vill du ha pris på montage?</label>
                                <Field name={`portar.${index}.montage`} value={formik.values.portar[index]?.montage} onChange={formik.handleChange} as = "select"  className='ContactMailFormSelect'>
                                    <option value={"Nej"}>Nej</option>
                                    <option value={"Ja"}>Ja</option>
                                </Field>

                                <label className='ContactMailFormLabel'>Vill du skicka in förfrågan på ytterligare portar?</label>
                                <Field id={`selectNewValue${index}}`} name={`portar.${index}.nyPort`} onChange={(e) => addForm(e,values,index)} as="select"  className='ContactMailFormSelect'>
                                    <option value={"Nej"}>Nej</option>
                                    <option value={"Ja"}>Ja</option>
                                </Field>
                            </div>
                        ))}
                        
                     </>
                 )}
             </FieldArray>
            )}
        </Formik>
    </div>
  )
}

export default PortForm