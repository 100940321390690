import React from 'react'
import "../styles/AboutCards.css"

function AboutCards(props) {

    const title = ["UNDERLÄTTA", "FÖRBÄTTRA", "FÖRSVÅRA"]
    const body = ["Lastning och lossning av gods vid varutransporter." , "Arbetsmiljön", "Inbrytningsförsök i affärs- och industrilokaler."]


  return (
    <div className='about-Card'>
        <header className='about-Card-Header'>{title[props.sort]}</header>
        <p className='about-Card-Body'>{body[props.sort]}</p>
    </div>
)}

export default AboutCards