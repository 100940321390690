import React from 'react'
import "../styles/ProductCard.css"
import {Link} from "react-router-dom"
import {useInfo} from "../context/PageContext"

function ProductCard(props) {
  const [info] = useInfo()
  return (
    <Link to={`/portar/${props.title}`} className='product-Card-Container'>
       <div className='product-Card'>
            <header className='product-Card-Title'>{props.title}</header>
            <p className='product-Card-Body'>{props.body}</p>
        </div>
        <img className='product-Card-ThumbNail' src={props.img}></img>
    </Link>
  )
}

export default ProductCard