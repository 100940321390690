import React, {useState, useEffect} from 'react'
import "../styles/LandingPage.css"
import BigBanner from '../components/BigBanner'
import InfoCard from '../components/InfoCard'
import LandingBox from '../components/LandingBox'
import FooterBar from "../components/FooterBar";
import NavBar from "../components/NavBar";
import WhiteNavBar from '../components/WhiteNavBar';
import "../styles/InfoCard.css"
import { TabTitle } from '../context/GeneralFunctions'
import { useInfo } from '../context/PageContext'
function LandingPage() {
  const [info] = useInfo()
  TabTitle("BBK International AB - Försäljning & montering av industriportar")
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  const [scrollPos, setScrollPos] = useState(0)
  document.addEventListener("scroll", () =>  {
    setScrollPos(document.documentElement.scrollTop)

  })

  return (
    <>
     {
      !info.isLoading?
      <>
      {
        scrollPos < 500?
        <NavBar></NavBar>:scrollPos > 500?
        <WhiteNavBar></WhiteNavBar>:
        []
      }
        <div className='body'>
          <LandingBox></LandingBox>
          <BigBanner sort={0} ></BigBanner>
          <div className='infoCard-Box'>
          <InfoCard sort={1} btnTo="/portar" btn="SE PORTAR!" ></InfoCard>
          <InfoCard sort={2} ></InfoCard>
          </div>
          <BigBanner sort={3} ></BigBanner>
        </div>
        <FooterBar></FooterBar>
      </>:[]
     }
    </>
  )
  
}

export default LandingPage