import React, { useEffect } from 'react'
import ProductHero from '../components/ProductHero'
import ProductNav from '../components/ProductNav'
import NavBar from '../components/NavBar'
import WhiteNavBar from '../components/WhiteNavBar'
import ProductGallery from '../components/ProductGallery'
import ProductInfo from '../components/ProductInfo'
import FooterBar from '../components/FooterBar'
import "../styles/ArticlePage.css"
import {useState} from "react"
import {useInfo} from "../context/PageContext"
import { useParams } from 'react-router-dom'
import ErrorPage from './ErrorPage'
import "../styles/ArticlePage.css"
import { TabTitle } from '../context/GeneralFunctions'

function ArticlePage() {
  const [info] = useInfo()
  const id = useParams()
  const [pageIndex, setPageIndex] = useState()
  TabTitle(`${id.typ} - BBK International AB `)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  useEffect(()=>{
    info.data.map((x, i) => {
      if(x.Hero.Header === id.typ){
        setPageIndex(i)
      }
    })
    window.scrollTo(0, 0)
  },[id.typ])

  const [scrollPos, setScrollPos] = useState(0)
  document.addEventListener("scroll", () =>  {
    setScrollPos(document.documentElement.scrollTop)

  })
  return (
   <>
     {
      info.isLoading === true?
        <div className='loading-Container'>
          <p className='loading-txt'>Laddar...</p>
        </div>:
      
      info.data[pageIndex]? 
      <>
        {
          scrollPos < 400?
          <NavBar></NavBar>:scrollPos > 400?
          <WhiteNavBar></WhiteNavBar>:
          []
        }
        <ProductHero img = {info.data[pageIndex].Hero.Img} title = {info.data[pageIndex].Hero.Header.toUpperCase()} body = {info.data[pageIndex].Hero.Body}></ProductHero>
        <ProductNav index = {pageIndex}></ProductNav>
        <ProductGallery></ProductGallery>
        <ProductInfo index = {pageIndex}></ProductInfo>
        <FooterBar></FooterBar>
      </>:<ErrorPage></ErrorPage>
     }
   </>
  )
}

export default ArticlePage