import React, { useEffect } from 'react'
import { useState } from 'react'
import { storage } from '../context/firebase'
import { useParams } from 'react-router-dom'
import { getDownloadURL, ref, listAll} from 'firebase/storage'
import { useImgViewer } from '../context/ImgViewerContex'
import ImgViewer from './ImgViewer'
import "../styles/SparesList.css"

function SparesList() {
    const [imgViewer, setImgViewr] = useImgViewer()
    const [sparesList, setSparesList] = useState([])
    let tempUrl = []
    const id = useParams()
    async function getSparesList(){
        const imgRef = ref(storage, "/Tillbehör/")
        const imgList = await listAll(imgRef)
        imgList.items.forEach((res) => {
         getDownloadURL(res).then((url) => {
            tempUrl.push(url)
         }).then(() => {
           setSparesList([...tempUrl])
         })
        });
       }
    
    function openImgViewr(src){
      setImgViewr({src: src, isOpen:true})
    }
    
    
    useEffect(() =>{
            getSparesList()
    },[])
    
    return (
    <>
      <div id="sLc" className='sparesList-Container'>
        <ul className='sparesList'>
            {
                sparesList?.map((url) => (
                         <img onClick={(e) => openImgViewr(url)} src={url} className='spareImg'></img>
                ))
            }
        </ul>
    </div>
   
    
    </>
    
  )
}

export default SparesList