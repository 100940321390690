import React from 'react'
import "../styles/InfoCard.css"
import BannerButton from './BannerButton'


function InfoCard(props) {
    const bannerHeader = ["VI VET VAD SOM BEHÖVS", "ÄR DU I BEHOV AV NY INDUSTRIPORT?","VEM ÄR DU?","HÖR AV DIG TILL OSS!"]
    const bannerBody = ["BBK International AB har sålt och monterat portar sedan 1987. Vi vet vad som fungerar och passar i olika miljöer och kan därmed erbjuda både hållbara produkter och kringtjänster.",
    "Oavsett om du letar efter en port med hög designnivå, specifikt anpassad till dina behov eller vikportar och garageportar i ett mer standardiserat utförande har vi den port du letar efter. Vi erbjuder även säkerhetsdörrar, termoportar, branddörrar, slagportar, skjutportar d.v.s industriportar i alla de slag. Mixen av vårat sortiment samt den höga kvalitén på portarna gör oss till en stark samarbetspartner vid byggnation.",
    "Med över 30 års erfarenhet av försäljning och montering av industriportar har vi nöjda kunder överallt i det större Stockholms området. Våra kunder finns inom allt från lager och industri till kund-anpassade P-garage och flerfamiljsfastigheter. Gemensamt för våra kunder är att de ställer tydliga krav på funktion, utseende och hållbarhet.","Hör av dig till oss för mer information, offert eller bara ställa frågor. Vi garanterar att vi kommer göra allt för att du ska bli nöjd."]
  return (
    <div id={`IC-${props.sort}`} className='infoCard-Container'>
           <div id={`infoCard${props.sort}`} className='infoCard-Body-Container'>
               <h2 className='infoCard-Header'>{bannerHeader[props.sort]}</h2>
               <div className='infoCard-Info-Container'>
                <p className='infoCard-Body'>{bannerBody[props.sort]}</p>
                {props.btn? 
                   <BannerButton btnTo={props.btnTo} body={props.btn}></BannerButton>:[]
                }
               </div>
           </div>
    </div>
    )
}

export default InfoCard