import React from 'react'
import "../styles/bannerButton.css"
import {Link} from "react-router-dom"

function BannerButton(props) {
  return (
    <Link to={props.btnTo} className='bigBanner-Button-Container'>
        <button id = {props.id}className='bigBanner-Button'>{props.body}</button>
    </Link>
  )
}

export default BannerButton