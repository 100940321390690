import React, {useEffect} from 'react'
import "../styles/AboutPage.css"
import AboutCards from '../components/AboutCards'
import FooterBar from "../components/FooterBar";
import NavBar from "../components/WhiteNavBar";
import { TabTitle } from '../context/GeneralFunctions';

function AboutPage() {

    const body1 = ` Bebeco International är bildat 1987. Sedan etableringen har företaget huvudsakligen varit sysselsatt med marknadsföring och installation av EAB portar.
    Mixen och den höga kvalitén på produkterna gör oss till en stark samarbetspartner vid byggnationer/ombyggnader.
    2001 ombildades BEBECO till aktiebolag och bytte där med namn till BBK International AB.`

    TabTitle("BBK International AB - Om oss")

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

  return (
    <>
    <NavBar></NavBar>
    <div className='aboutInfoContainer'>
        <div id="info1"className='about-Info-Container'>
            <h1 className='about-Info-Header'>OM BBK INTERNATIONAL AB</h1>
            <p className='about-Info-Body'>
                {body1}
            </p>
        </div>
        <div id="info2" className='about-Info-Container'>
            <h1 className='about-Info-Header'>Affärsidé</h1>
            <div className='about-Cards-Container'>
                <AboutCards sort={0}></AboutCards>
                <AboutCards sort={1}></AboutCards>
                <AboutCards sort={2}></AboutCards>
            </div>
            <p className='about-Info-Body'>
                BBK:s samarbetspartner EAB, som ligger i Smålandsstenar. Få kunder ligger längre bort än att de kan nås på kort tid. Tillsamans med ett reservdelslager som alltid hålls komplett, betyder det att montering, service och reperationer kan utföras med mycket kort varsel.
                EAB tillverkar själv hela porten. Motorspelen och automatikskåpen levereras av DAAB.
                Portarna "skräddarsys" exakt efter beställarens önskemål. Naturligtvis har portarna ett standardutförande när det gäller detaljutformningen, men om du har speciella önskemål, kan vi tillverka porten precis som du vill ha den. </p>
        </div>
            <div id="info3" className='about-Info-Container'>
                <h1 className='about-Info-Header'>Kvalitetssäkring</h1>
                <p className='about-Info-Body'>
                Denna kvalitetssäkringshandbok för BBK International AB har till syfte att precisera den målsättning som firman har för kvalitet och säkring av densamma.
                D.v s. att säkra att våra leverantörer och montörer håller de kvalitetskrav vi och våra kunder ställer på produkten.
                Vid löpande efterkontroller, säkrar vi oss samtidigt om att montage, underhåll och reparationer utförs, efter de föreskrifter vi och våra leverantörer ställer på våra montörer och servicetekniker.
                </p>
        </div>
    </div>
    <FooterBar></FooterBar>
    </>
  )
}

export default AboutPage