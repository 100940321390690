import React from 'react'
import {useState, useContext } from 'react'

const SideBarContext = React.createContext()

export function useSideBar(){
    return useContext(SideBarContext)
}

export function SideBarProvider({children}) {

    const [sideBar, setSideBar] = useState({type: "", open: false, focus: ""}) 

  return (
    <SideBarContext.Provider value = {[sideBar, setSideBar]}>
        {children}
    </SideBarContext.Provider>
  )
}

export default SideBarContext